.context-buttons {
  padding-right: 10px;
  .primary,
  .secondary {
    margin-left: 10px;
  }
}

.button-wrapper {
  position: relative;
  .dropdown {
    position: absolute;
    top: -5px;
    right: 0;
    min-width: 160px;
    background: #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24);
    z-index: 99;
    &.closed {
      display: none;
    }
    &.open {
      display: block;
    }

    button {
      background: #fff;
      display: block;
      appearance: none;
      border: none;
      line-height: normal;
      width: 100%;
      padding: 8px 16px;
      text-align: right;
      cursor: pointer;
      outline: 0;
      white-space: nowrap;
      &:hover {
        background: #efefef;
      }
    }
  }
}
