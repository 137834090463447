.address {
  .line1,
  .line2,
  .line3 {
    display: block;
  }
  .city,
  .state,
  .zip {
    display: inline-block;
  }

  .city::after,
  .state::after {
    content: ', ';
    white-space: pre;
  }
}

.data-label {
  font-weight: bold;
}
