
@import 'colors';

.actionable-list-item {
  display: flex;
  font-family: 'Roboto';
  width: 100%;
  box-sizing: border-box;
  box-shadow: none;
  max-width: 100%;
  align-items: center;

  >.list-item-icon {
    display: flex;
    padding: 2px;
    width: 24px;
    height: 24px;
    margin-right: 6px;

    >.iconOutline {
      color: white;
      font-size: 14px;
      padding: 2px;
      border-radius: 50px;
    }
  }

  >.actions {
    display: flex;
    margin-left: auto;

    button {
      background-color: inherit;
      color: $action-button-color;
      border: none;

      &:focus {
        outline: none;
      }
    }
  }

}