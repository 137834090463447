.gallery {
  background: white;

  >.header {
    font-size: 36px;
    font-weight: 700;
    text-align: left;
    padding-left: 1rem;
  }

  >.sub-header {
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    padding-left: 1rem;
  }
}


