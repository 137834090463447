.main-header {
  .profile {
    > span {
      font-size: 1.4em;
      display: inline-block;
      margin: 0 .75em;
    }
    flex-grow: 8;
  }
  .logo {
    height: 1.3em;
  }
}
