@import './common.scss';

html,
body,
#root {
  height: 100%;
  font: $primary-font;
  padding: 0;
  margin: 0;
}

.mwa {
  height: 100%;
  width: 100%;
}

.main-header {
  width: 100%;
  height: 64px;
  @media (max-width:800px) {
    height: 80px;
  }
  @media (max-width:650px) {
    height: 110px;
  }
  @media (max-width:378px) {
    height: 130px;
  }
  background: linear-gradient(0deg, #42711E, #42711E), #8EB939;
  top: 0;
  left: 0;
  z-index: 1000;

  .mwhc-link-box {
    width: 100%;
    display: flex;
    flex-direction: row;
    @media (max-width:800px) {
      flex-direction: column;
    }
    align-items: center;
    justify-content: center;
    padding: 0 20px;

    text-align: center;
    font-size: 16px;
  }
  .mwhc-link-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 0;
    cursor: pointer;

    position: static;
    width: 275px;

    background: #7EBA00;

    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.08), 0px 8px 8px -4px rgba(0, 0, 0, 0.08);
    border-radius: 999px;

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0 24px;
  }
  .menu-holder {
    margin-right: 12px;
  }
  .menu-hamburger {
    position: relative;
    display: inline-block;
    overflow: visible;
    margin: 0;
    padding: 5px;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition-duration: 75ms;
    text-transform: none;
    color: inherit;
    border: 0;
    background-color: transparent;

    .hamburger-box {
      position: relative;
      display: inline-block;
      width: 3em;
      height: 12px;

      .hamburger-inner,
      .hamburger-inner::before,
      .hamburger-inner::after {
        background-color: #fff;
        display: block;
        position: absolute;
        width: 20px;
        height: 2px;
        transition-timing-function: ease;
        transition-duration: 0.15s;
        transition-property: transform;
      }

      .hamburger-inner {
        top: 50%;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        transition-duration: 75ms;

        &::before,
        &::after {
          display: block;
          content: '';
        }

        &::before {
          transition: top 75ms ease 0.12s, opacity 75ms ease;
          top: -5px;
        }

        &::after {
          transition: bottom 75ms ease 0.12s, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
          bottom: -5px;
        }
      }
    }

    &.open {
      .hamburger-inner {
        transition-delay: 0.12s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: rotate(45deg);

        &::before {
          top: 0;
          transition: top 75ms ease, opacity 75ms ease 0.12s;
          opacity: 0;
        }

        &::after {
          bottom: 0;
          transition: bottom 75ms ease, transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
          transform: rotate(-90deg);
        }
      }
    }
  }

  .header-wrapper {
    width: 100%;
    max-width: calc(100% - 55px);
    padding: 18px 0 0;
    margin: 0 1.5em;
    color: #fff;

    > div:first-child {
      flex-grow: 1;
    }

    .brand {
      font-size: 1.3em;
      a {
        color: #fff;
        text-decoration: none;
      }
    }

    .user {
      .logout {
        color: #fff;
        text-decoration: none;
        font-size: 1.25em;
      }
    }
  }
}

.main-footer {
  width: 100%;
  height: 64px;
  background-color: #fff;

  .footer-wrapper {
    width: 100%;
    max-width: 1280px;
    padding: 24px 0;
    margin: 0 auto;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12.64px;

    .footer-menu {
      li {
        padding: 0 15px;
        max-height: 12px;
        line-height: 12.64px;
        border-right: 1px solid rgba(0, 0, 0, 0.25);
        &:last-child {
          border-right: none;
        }

        a {
          color: rgba(0, 0, 0, 0.25);
          text-decoration: none;
          &:hover,
          &.active {
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }
}

.content-wrapper {
  // we want to shave off the 64px from the header
  // and the 80px from the padding top and bottom
  min-height: calc(100% - 124px);
  width: 100%;
  padding: 1.15em 0;
  background-color: #efefef;
}

.auth0-lock-widget {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.12),
    0 1px 3px 0 rgba(0, 0, 0, 0.2);
}

a,
a:visited,
a:active {
  color: #5c9ec4;
  text-decoration: none;
}

.bg-warn {
  background-color: $warning-color;
  color: #fff;
}
.warn:not(.alert) {
  color: $warning-color;
}

button:disabled {
  cursor: not-allowed !important;
  color: #aaa;
}

button {
  &.borderless {
    cursor: pointer;
    outline: none;
    background: transparent;
    border: none;
  }
  &.primary,
  &.secondary {
    font-size: 15px;
    min-width: 20px;
    padding: 5px 10px;
    border-radius: 2px;
    text-align: center;
    line-height: normal;
    outline: 0;
    cursor: pointer;

    i {
      vertical-align: middle;
      margin-right: -5px;
      line-height: 15px;
    }
  }
  &.primary {
    border: none;
    background: linear-gradient(180deg, #b4e183 0%, #77b24d 100%);
    box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.06);
    color: #fff;
    &:active:enabled {
      background: #77b24d;
    }
    &.open {
      background: #77b24d;
    }
  }
  &.secondary {
    border: none;
    box-shadow: inset 0px 0px 0px 1px rgba(100, 102, 103, 0.54);
    background-color: #ffffff;
    background: linear-gradient(0deg, #f0f0f0 0%, #ffffff 100%);
    color: rgba(100, 102, 103, 0.87);

    &.open {
      background: #f0f0f0;
    }

    i {
      margin-right: auto;
    }
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

div {
  &.bg-primary {
    background-color: $secondary-green;
    color: #fff;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #fff;
    }
  }
}

.buttonlike {
  input,
  svg {
    cursor: pointer;
  }
}

a.buttonlike {
  cursor: pointer; // find a fancy SASS way to attach this to that ^
}

.copied {
  $copy-highlight-color: $danger-color;

  &:not(label) {
    border-bottom: 2px solid $copy-highlight-color !important;
  }

  label {
    color: $copy-highlight-color !important;
  }

  @at-root {
    label#{&} {
      color: $copy-highlight-color !important;
    }
  }
}

li.focused {
  background: #efefef;
}

.overflow-wrap {
  overflow-wrap: break-word;
}

.add-required-star:after {
  content: "*";
  color: #f00;
  padding-left: 0.25em;
}
