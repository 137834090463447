@import '../../../styles/common.scss';

.portal-background {
  z-index: 1200;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  &.blackout {
    background-color: rgba(255, 255, 255, 1);
  }
}

.portal-modal {
  position: fixed;
  z-index: 1200;
  max-height: 90%;
  max-width: 100%;
  width: 600px;
  min-height: 400px;
  background: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24);
  border-radius: 0 0 4px 4px;
  transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);

  &.wide {
    width: 900px;
  }
  &.full {
    width: 90%;
    height: 90%;
  }
  .portal-footer {
    min-height: 56px;
  }

  .portal-header {
    background: $primary-color;
    padding: 8px 16px;
    color: #fff;
    min-height: 48px;
    h4 {
      padding: 0;
      margin: 0;
      font-weight: normal;
      font-size: 16px;
    }
    a {
      color: #fff;
      cursor: pointer;
    }
  }

  .portal-content {
    overflow: hidden;
    overflow-y: auto;
    padding: 16px;
    min-height: calc(100% - 104px);
  }
}
