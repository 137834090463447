.medwise {
  background: transparent;
  padding: 1rem;

  button {
    align-items: center;
    border: 1px transparent solid;
    border-radius: 4px;
    box-shadow: none;
    display: inline-flex;
    height: 40px;
    margin-left: 1rem;
    margin-right: 1rem;
    padding-left: 3rem;
    padding-right: 3rem;
    position: relative;
    width: 140px;
    font-size: 1.1rem;

    i[class*='hidden'] {
      display: none;
    }

    i[class*='material-icons'] {
      position: absolute;
      left: 10%;
    }

    &:focus {
      outline-color: transparent;
      box-shadow: 0 0 0 3px #4189f7;
    }

    &.primary-btn {
      background: #8cb738;
      color: #fff;
      &:active {
        //
        &:hover:focus {
          background: #7ca431;
          outline-color: transparent;
          box-shadow: none;
        }
      }
      &:hover {
        background: #94bf3c;
        // Force click to not ALSO show the focus border.
        &:focus {
          box-shadow: none;
        }
      }
      &.promise-disabled {
        opacity: 70%
      }
      &.promise-pending {
        background: #7ca431;
      }
    }
    &.secondary-btn {
      border: 1px #e0e0e0 solid;
      background: #fff;
      color: black;
      &.disabled {
        color: #949494;
        & > i.material-icons {
          color: grey;
        }
      }
      i.material-icons{
        color: #47ADA4;
      }
      &:hover {
        background: rgba(0,0,0,0.04);
      }
      &:active {
        background: rgba(0,0,0,0.08);
      }
      &.promise-pending {
        background: rgba(0,0,0,0.08);
      }
    }
    &.tertiary-btn {
      border: 1px transparent solid;
      background: #fff;
      color: #4189f7;
      &.disabled {
        color: #949494;
      }
      i[class*='material-icons']{
        color: #47ADA4;
      }
      &:hover {
        background: rgba(0,0,0,0.04);
      }
      &:active {
        background: rgba(0,0,0,0.08);
      }
      &.promise-pending {
        background: rgba(0,0,0,0.08);
      }
      &.promise-resolved {
        color: black;
      }
    }
  }
}
