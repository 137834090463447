@import '../../../styles/common.scss';

.tabbed-box {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto 20px;
  background: #fff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.12),
    0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border-radius: 0 0 3px 3px;

  .tab-container {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #e6e6e6;
    background-color: #fafafa;
    position: relative;
    .tab {
      background-color: #fafafa;
      padding: 7px 12px 10px;
      height: 40px;
      border-left: 1px solid #fafafa;
      border-right: 1px solid #fafafa;
      border-bottom: 1px solid #e6e6e6;
      border-top: 3px solid #fafafa;
      cursor: pointer;

      &:focus {
        outline: 0;
      }

      &.active {
        background-color: #fff;
        color: $primary-color;
        border-bottom: 1px solid #fff;
        border-left: 1px solid #e6e6e6;
        border-right: 1px solid #e6e6e6;
        border-top: 3px solid $primary-color;

        &:first-child {
          border-left: 1px solid #fff;
        }
      }

      &.error {
        color: $danger-color;
        border-top-color: $danger-color;
      }
    }

    &.children {
      background-color: #fff;

      .tab {
        background-color: #fff;
        border-left: 1px solid #fff;
        border-right: 1px solid #fff;
        &.active {
          border-left: 1px solid #fff;
          border-right: 1px solid #fff;
          border-top: 3px solid #fff;
          border-bottom: 1px solid #e6e6e6;
        }
      }
    }

    .context-buttons {
      position: absolute;
      right: 0;
      top: 0;
      height: 40px;
      line-height: 40px;
    }
  }

  .tab-contents {
    padding: 20px;
  }
}
