@import '../../../styles/common.scss';

.react-select__container {
  margin-right: 0.5rem;
  .react-select__control {
    &:hover {
      border-color: hsl(0, 0%, 80%);
    }
    box-shadow: none;
    border-color: hsl(0, 0%, 80%);
    background-color: #fff;
    min-height: 0;
  }
  .react-select__value-container {
    background-color: #fff;
  }
  .react-select__option {
    &:active {
      background-color: $trhc-green-50;
    }
  }
}
