$tableHeaderBackground: #e9f1fb;

table.nested {
  border-spacing: 0 2px;
  > thead > tr {
    background-color: $tableHeaderBackground;
    > td {
      &.actions,
      .actions {
        button {
          cursor: pointer;
          outline: none;
          background: transparent;
          border: none;
        }
      }
      &.header-row {
        cursor: pointer;
      }
    }
  }
}
