$primary-color: #82bc02;
$secondary-color: #03a9f4;
$success-color: #4caf50;
$info-color: #673ab7;
$danger-color: #f44336;
$warning-color: #ff9800;
$direction-color: #4591cf;
$primary-font: 14px 'Roboto', sans-serif;

$trhc-green: $primary-color;
$trhc-green-25: rgba(130, 188, 0, 0.25);
$trhc-green-50: rgba(130, 188, 0, 0.5);
$trhc-green-75: rgba(130, 188, 0, 0.75);

$secondary-green: #96c940;

// This color is not applied everywhere; came about for TOA chip disabled bground
$disabled-color: #e0e0e0;

.login {
  display: table;
  width: 100%;
  height: 100%;
  .login-wrapper {
    display: table-cell;
    vertical-align: middle;

    .mwa-disclaimer {
      max-width: 1280px;
      margin: 0 auto;
      padding: 15px;
      color: rgba(0, 0, 0, 0.7);
    }
  }
}

.page {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 2px 0 rgba(0, 0, 0, 0.12),
    0 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.flex.profile {
  align-items: center;
}
