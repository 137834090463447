// border color should also be used for icons
$text-color: #212121;
$help-border: #2388ff;
$help-fill: #e9f3ff;
$success-border: #47ada4;
$success-fill: #ecf6f5;
$warning-border: #f9a36c;
$warning-fill: #fef5f0;
$error-border: #d85a65;
$error-fill: #fbeeef;
$info-border: #303e4c;
$info-fill: #eaebed;

.styled-notification {
  &.container {
    position: relative;
    display: flex;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: none;
    max-width: 100%;
    margin: 2px; // this accounts for the keyboard access. outline

    > .message {
      color: $text-color;
      font-size: 16px;
      line-height: 24px;
      word-break: break-word;
      padding: 12px;
    }

    > .content-right {
      margin-left: auto;
    }

    &.help {
      border: 1px solid $help-border;
      background-color: $help-fill;
    }
    &.help:hover {
      // Without this, the cursor shows as a question mark
      cursor: auto;
    }

    &.success {
      border: 1px solid $success-border;
      background-color: $success-fill;
    }

    &.warning {
      border: 1px solid $warning-border;
      background-color: $warning-fill;
    }

    &.error {
      border: 1px solid $error-border;
      background-color: $error-fill;
    }

    &.info {
      border: 1px solid $info-border;
      background-color: $info-fill;
    }
  }

  &.container:focus-within::before {
    position: absolute;
    content: ' ';
    z-index: 0;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border: 2px solid #2388ff;
    border-radius: 4px;
  }
}

:export {
  helpIcon: $help-border;
  successIcon: $success-border;
  warningIcon: $warning-border;
  errorIcon: $error-border;
  infoIcon: $info-border;
}
