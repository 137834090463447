@import 'colors';

.list-wrapper {
  font-family: 'Roboto';

  >.list-item-expanded {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    margin: 12px 0 !important;
  }

  >.list-item {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    align-items: center;
    border-radius: 4px;
    border: 1px solid $list-item-border-color;
    margin-bottom: 4px;
    padding: 8px;
    align-items: center;
    min-height: 56px;

    >.summary {
      font-weight: bold;
      margin-left: auto;
      width: 100%;
    }

    &:hover {
      background-color: $hover-background-color;
      border-color: $default-border-color;
      
      button {
        background-color: inherit;
      }
    }

    &.success {
      &:hover {
        border: 1px solid $success-border-color;
      }
    }

    &.new {
      &:hover {
        border: 1px solid $new-border-color;
      }
    }

    &.modify {
      &:hover {
        border: 1px solid $modify-border-color;
      }
    }

    &.warning {
      &:hover {
        border: 1px solid $warning-border-color;
      }
    }

    &.error {
      &:hover {
        border: 1px solid $error-border-color;
      }
    }
  }
}
