.pagination {
  color: #333333;
  padding-top: 12px;
  padding-bottom: 12px;

  .pagination-summary {
    margin-right: 16px;
  }

  .pagination-number {
    min-width: 1.5rem;
    padding: 0.5rem;
    text-align: center;
  }

  .pagination-pages {
    line-height: 34px;

    .pagination-button {
      font-family: 'Roboto';
      appearance: none;
      cursor: pointer;
      border: 1px solid #ddd;
      border-right: 0;
      line-height: 34px;
      height: 34px;
      min-width: 28px;
      padding: 0 8px;
      margin: 0;
      outline: none;
      color: #777;
      background-color: #fafafa;

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
        border-right: 1px solid #ddd;
      }

      &.active {
        color: #fff;
        background: #646667;
        border-color: #646667;
      }
    }
  }
}
