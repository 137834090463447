.eula-content {
  height: 400px;
  overflow-y: scroll;
  // border: 1px solid #ccc;
  // padding: 1em;
  position: relative;

  // .loading {
  //   position: absolute;
  //   left: 50%;
  //   top: 50%;
  //   transform: translate3d(-50%, -50%, 0);
  // }

}
@media print {
  .no-print {
    display: none !important;
  }
  .eula-content {
    height: auto;
    border: none;
  }
}