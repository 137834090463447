.stepper-wrapper {
  width: 100%;
}

.stepper {
  counter-reset: step;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  li {
    list-style-type: none;
    float: left;
    font-size: 12px;
    position: relative;
    text-align: left;
    align-items: center;
    color: #7d7d7d;
    font-family: 'Roboto';

    &:before {
      font-size: 16px;
      width: 30px;
      height: 30px;
      content: counter(step);
      counter-increment: step;
      line-height: 30px;
      border: 2px solid #7d7d7d;
      text-align: center;
      margin: 0 10px 10px 10px;
      border-radius: 50%;
      background-color: #7d7d7d;
      color: white;
      float: left;
    }

    &:after {
      width: (100% / 1.25);
      height: 1px;
      content: '';
      position: absolute;
      background-color: #7d7d7d;
      top: 15px;
      left: calc(100% / -1.25);
    }

    &:first-child:after {
      content: none;
    }

    .title {
      font-size: 14px;
    }

  }

  li.active {
    &:before {
      border-color: #55b776;
      background-color: #55b776;  
    }  

    .title {
      font-weight: bold;
      color: #333333;
      font-size: 14px;
    }
  }

  li.complete {
    &:before {
      content: "\2713";
    }
  } 

}

  // .stepper 
  // .stepper li.active + li:after {
  //     background-color: #55b776;
  // }