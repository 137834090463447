.mrs-bar {
  min-width: 100px;
  .background {
    width: 100%;
    background: #efefef;
    .bar {
      font-size: 1.5em;
      color: #000000;
      text-align: right;
      padding-right: 1em;
      padding-top: 5px;
      padding-bottom: 5px;
      white-space: nowrap;
      &.very-low {
        text-align: left;
        padding-left: 1em;
        padding-right: 0;
      }
      &.very-high {
        color: #ffffff;
      }
      .score {
        padding-left: 3px;
      }
    }
  }
  .very-low {
    background: #99ccff;
  }
  .low {
    background: #ffff99;
  }
  .moderate {
    background: #fccc00;
  }
  .high {
    background: #ff9900;
  }
  .very-high {
    background: #ff0000;
  }
  .legend {
    .severity {
      position: relative;
      display: inline-block;
      text-align: center;
      font-size: 0.85em;
      color: #a1a3a8;
      line-height: 1.5em;
      margin-top: 0.2em;
      &.active {
        .description {
          color: #000000;
          font-weight: bold;
        }
      }
      .tick {
        position: absolute;
        top: 0;
        right: 0;
        border: 1px solid #ddd;
        height: 0.8em;
        width: 0;
      }
      &:last-child .tick {
        display: none;
      }
      .name {
        display: inline-block;
        height: 1em;
        width: 1em;
        position: relative;
        margin: 0 0.2em 0 0;
        top: 0.2em;
      }
      .range {
        display: inline-block;
      }
    }
  }

  &.small {
    display: inline-block;
    .background {
      .bar {
        font-size: 0.8em;
        padding-right: 0.8em;
        padding-top: 1px;
        padding-bottom: 1px;
        &.very-low {
          padding-left: 0.8em;
        }
      }
    }
    .legend {
      display: none;
    }
  }
}
