
$default-border-color: #949494;
$default-background-color: white;

$hover-background-color: rgba(0,0,0,0.04);

$success-border-color: #7EBA00;
$new-border-color: #47ADA4;
$modify-border-color: #2388FF;
$warning-border-color: #F9A36C;
$error-border-color: #D85A65;

$list-item-border-color: #E0E0E0;
$action-button-color: #616161;
