.component.table {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: 20px;

  .striped--near-white:nth-child(odd) {
    background-color: #f7f7f7;
  }

  .header-row {
    &.striped--near-white {
      background-color: #fff;
    }
  }

  th {
    color: rgba(0, 0, 0, 0.87);
  }

  td {
    // ensure row line-height on empty rows
    &:empty:after {
      content: '\00a0';
      line-height: 24px;
    }

    color: #333;
    border-top: 1px solid #e0e0e0;

    &.actions {
      white-space: nowrap;
      button {
        cursor: pointer;
        outline: none;
        background: transparent;
        border: none;
      }
    }
  }
}

.truncateEllipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.drag-icon {
  color: #757575;
}

