@import '../../../styles/common.scss';

.notifications {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 0 30px;
  z-index: 1300;
}

.alert {
  position: relative;
  margin: 0 0 1rem;
  background-color: $info-color;
  transition: box-shadow 0.25s;
  border-radius: 2px;
  overflow: hidden;
  color: #fff;

  .alert-content {
    margin: 0;
    padding: 20px;
    border-radius: 0 0 2px 2px;
  }

  button {
    background: none;
    border: none;
    position: absolute;
    top: 15px;
    right: 10px;
    float: right;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
  }

  &.success {
    background-color: $primary-color;
  }
  &.error {
    background-color: $danger-color;
  }
  &.warn {
    background-color: $warning-color;
  }
}
